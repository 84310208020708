import { NavigationStart, Router } from '@angular/router';

import { ElementRef, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AppIcon } from 'weavix-shared/models/icon.model';
import { sleep } from '../utils/sleep';
import { TranslationService } from './translation.service';

export enum SnackBarType {
    Success = 'success',
    Error = 'error',
    Prompt = 'prompt',
    Info = 'info',
}

export interface SnackBar {
    message: string;
    action: string;
    type: SnackBarType;
    timeout: number;
    response?: Subject<boolean>;
    cssClass?: string;
    icon?: AppIcon;
    buttonProps?: SnackBarButtonProps;
    containerClass?: string;
    parentContainer?: ElementRef;
}

export interface SnackBarButtonProps {
    label: string;
    icon: AppIcon;
}

export enum ServiceError {
    Get = 'GET',
    Add = 'ADD',
    Update = 'UPDATE',
    Delete = 'DELETE',
    Import = 'IMPORT',
}

export function getErrorText(e) {
    if (e.details) {
        if (e.details.reason) return e.details.reason;
        if (e.details.field) return e.details.field;
        if (e.message) return e.message;
    } else {
        return e.message || e;
    }
}

@Injectable({
    providedIn: 'root',
})
export class AlertService {
    static appLoadingSubject: Subject<boolean> = new Subject();

    private alertSubject: Subject<SnackBar> = new Subject();
    public alert$: Observable<SnackBar> = this.alertSubject;

    skip: boolean;

    constructor(
        private translationService: TranslationService,
        private router: Router,
    ) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                AlertService.appLoadingSubject.next(false);
            }
        });
    }

    static setAppLoading(isLoading: boolean): void {
        AlertService.appLoadingSubject.next(isLoading);
    }

    get successIcon(): AppIcon { return { faIcon: 'fa-light fa-circle-check', fontSize: '20px' } as AppIcon; }
    get errorIcon(): AppIcon { return { faIcon: 'fa-light fa-circle-xmark', fontSize: '20px' } as AppIcon; }
    get infoIcon(): AppIcon { return { faIcon: 'fa-light fa-exclamation-triangle', fontSize: '20px' } as AppIcon; }

    sendError(error: Error, message: string, params?: any, options?: { timeout?: number, icon?: boolean, parentContainer?: ElementRef }) {
        message = this.translationService.getImmediate(message, params);
        if (error) {
            const errorText = getErrorText(error);
            console.error(errorText, error);
        }

        const errorData: SnackBar = {
            message,
            action: 'Error',
            type: SnackBarType.Error,
            timeout: options?.timeout ?? 3000,
            cssClass: this.getSnackBarClass(SnackBarType.Error),
            icon: options?.icon ? this.errorIcon : null,
            parentContainer: options?.parentContainer,
        };
        if (!this.skip) this.alertSubject.next(errorData);
    }

    sendSuccess(message: string, params?: any, options?: { timeout?: number, icon?: boolean, parentContainer?: ElementRef }, containerClass?: string) {
        message = this.translationService.getImmediate(message, params);
        const successData: SnackBar = {
            message,
            action: 'Error',
            type: SnackBarType.Success,
            timeout: options?.timeout ?? 3000,
            cssClass: this.getSnackBarClass(SnackBarType.Success),
            icon: options?.icon ? this.successIcon : null,
            containerClass,
            parentContainer: options?.parentContainer,
        };
        if (!this.skip) this.alertSubject.next(successData);
    }

    sendInfo(message: string, params?: any, icon?: boolean, timeout?: number, buttonProps?: SnackBarButtonProps) {
        const response: Subject<boolean> = new Subject();
        message = this.translationService.getImmediate(message, params);
        const infoData: SnackBar = {
            message,
            action: 'Print Guides',
            type: SnackBarType.Prompt,
            timeout: timeout || 0,
            response,
            cssClass: this.getSnackBarClass(SnackBarType.Info),
            icon: icon ? this.infoIcon : null,
            buttonProps,
        };
        if (!this.skip) this.alertSubject.next(infoData);
        return response;
    }

    sendPrompt(message: string, action: string, params?: any): Subject<boolean> {
        const response: Subject<boolean> = new Subject();
        message = this.translationService.getImmediate(message, params);
        action = this.translationService.getImmediate(action);
        const data = {
            message,
            action: action.toLocaleUpperCase(),
            type: SnackBarType.Prompt,
            timeout: 0,
            response: response,
            cssClass: this.getSnackBarClass(SnackBarType.Prompt),
        };
        if (!this.skip) this.alertSubject.next(data);
        return response;
    }

    private getSnackBarClass(type: SnackBarType): string {
        if (type === SnackBarType.Error) return 'mat-snackbar-error';
        if (type === SnackBarType.Success) return 'mat-snackbar-success';
        if (type === SnackBarType.Info) return 'mat-snackbar-info';
        else return 'mat-snackbar-prompt';
    }

    sendServiceError(error: Error, serviceError: ServiceError, itemKey: string) {
        const errorText = getErrorText(error);
        const translatedError = this.translationService.getImmediate(`ERRORS.MISC.${errorText.toUpperCase()}`);
        const errorKey = `ERRORS.GENERIC.${serviceError}`;
        let translatedItem = this.translationService.getImmediate(itemKey);
        if (errorText !== translatedError) translatedItem += ` ${translatedError}`;
        this.sendError(error, errorKey, { item: translatedItem });
    }

    setAppLoading(isLoading: boolean): void {
        AlertService.appLoadingSubject.next(isLoading);
    }

    async withLoading<T>(promise: Promise<T>): Promise<T> {
        try {
            this.setAppLoading(true);
            return await promise;
        } finally {
            this.setAppLoading(false);
        }
    }

    async skipError() {
        this.skip = true;
        await sleep(1000);
        this.skip = false;
    }
}
