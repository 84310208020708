import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Account } from 'weavix-shared/models/account.model';
import { LoggedInUser } from 'weavix-shared/models/user.model';

// we tried way too long to get @newrelic/browser-agent to work...
// we are importing the script from index.html instead
declare const newrelic: any;

@Injectable({ providedIn: 'root' })
export class NewRelicService {
    
    static initializeNewRelic() {
        if (!newrelic) return;
        console.log(environment.version);
        newrelic.setApplicationVersion(environment.version);
        newrelic.addRelease(environment.releaseStage, environment.version);
        newrelic.start();
    }

    static sendError(error: Error) {
        if (!newrelic) return;
        newrelic.noticeError(error);
    }

    setUser(user: LoggedInUser | null) {
        if (!newrelic) return;
        if (!user) this.resetUserAttributes();
        else {
            newrelic.setUserId(user?.id);
            newrelic.setCustomAttribute('userName',`${user.firstName} ${user.lastName}`);
        }
    }

    setAccount(account: Account | null) {
        if (!newrelic) return;
        newrelic.setCustomAttribute('weavixAccountId', account.id ?? null);
        newrelic.setCustomAttribute('weavixAccountName', account.name ?? null);
    }

    resetUserAttributes() {
        if (!newrelic) return;
        newrelic.setUserId(null);
        newrelic.setCustomAttribute('userName', null);
        newrelic.setCustomAttribute('weavixAccountId', null);
        newrelic.setCustomAttribute('weavixAccountName', null);
    }
}